import Service from '../Service'
import { LandingV4Instance } from './Instance'

/**
 * @class
 * @param  {String} landingApplicationId
 */
export class VerificacionesTuboService extends Service {
  // constructor() {
  //   super()    
  // }
  cavSectionPhone(creditApplicationId) {
    // const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: `/cav/section?creditApplicationId=${creditApplicationId}&jsonQuery=person.phone`,
      // data: self.data
    }))
  }

  cavSectionMail(creditApplicationId) {
    // const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: `/cav/section?creditApplicationId=${creditApplicationId}&jsonQuery=person.email`,
      // data: self.data
    }))
  }

  updatePhone(creditApplicationId, validatePolitics, phone) {
    // const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: `/roadmap/${creditApplicationId}/VERITELE/${phone}`,
      // url: `/roadmap/${creditApplicationId}/${validatePolitics}/VERITELE/${phone}`,      
      // data: self.data
    }))
  }

  updateMail(creditApplicationId, validatePolitics, mail) {
    // const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      url: `/roadmap/${creditApplicationId}/VERIEMAIL/${mail}`,
      // data: self.data
    }))
  }
  
  allowContactByWhatsapp(creditApplicationId, updateTask, phone, optIn) {
    // const self = this
    return this._requestWrapper(LandingV4Instance({
      method: 'GET',
      // url: `/roadmap/${creditApplicationId}/${updateTask}/VERITELE/${phone}?optIn=${optIn}`,
      url: `/roadmap/${creditApplicationId}/VERITELE/${phone}?optIn=${optIn}`,
      // data: self.data
    }))

  }
}
